/********************************************************************************
 * ルーター
 *
 * @since 2023/02/20
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

// フレームワークのインポート
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

// ※ルーターでは、useStore()ではなく、直接storeをインポートして使用する。
import { store } from "@/store" 

//#endregion

//#region ルート設定

// ルート設定
const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SignInView.vue')
    }
  },
  {
    path: '/setting',
    name: 'setting',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SettingEditView.vue')
    }
  }, 
  {
    path: '/signin',
    name: 'signin',
    meta: { requiresAuth: false },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SignInView.vue')
    }
  },
  {
    path: '/match',
    name: 'match',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MatchView.vue')
    }
  },
  {
    path: '/competitionblock',
    name: 'competitionblock',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CompetitionBlockView.vue')
    }
  },
  {
    path: '/set',
    name: 'set',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SetView.vue')
    }
  },
  {
    path: '/side',
    name: 'side',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SideEditView.vue')
    }
  },
  {
    path: '/umpire',
    name: 'umpire',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/UmpireEditView.vue')
    }
  },
  {
    path: '/team',
    name: 'team',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/TeamEditView.vue')
    }
  },
  {
    path: '/signedit',
    name: 'signedit',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/SignEditView.vue')
    }
  },
  {
    path: '/order',
    name: 'order',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/OrderView.vue')
    }
  },
  {
    path: '/medama',
    name: 'medama',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MedamaView.vue')
    }
  },
  {
    path: '/medamafour',
    name: 'medamafour',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/MedamaFourView.vue')
    }
  },
  {
    path: '/orderselect',
    name: 'orderselect',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/OrderSelectView.vue')
    }
  },
  {
    path: '/coin',
    name: 'coin',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/CoinEditView.vue')
    }
  },
  {
    path: '/if',
    name: 'if',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/IFView.vue')
    }
  },
  {
    path: '/detail',
    name: 'detail',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/DetailView.vue')
    }
  },
  {
    path: '/time',
    name: 'time',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/TimeView.vue')
    }
  },
  {
    path: '/change',
    name: 'change',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ChangeView.vue')
    }
  },
  {
    path: '/changeselect',
    name: 'changeselect',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ChangeSelectView.vue')
    }
  },
  {
    path: '/result',
    name: 'result',
    meta: { requiresAuth: true },
    components: {
      header: () => import('../components/HeaderMenu.vue'),
      main: () => import('../views/ResultView.vue')
    }
  },
]

// ルーターインスタンスの作成
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// グローバルビフォーガードの登録
router.beforeEach((to, from, next) => {

  // 認証が必要なページで且つトークンがない場合
  if (to.matched.some((record) => record.meta.requiresAuth) && store.state.auth.Token === "") {

    // サインインページへ遷移
    next({ path: '/signin', query: { redirect: to.fullPath } });

  // 通常の場合
  }else{

    // 次ページへ遷移
    next();

  } 
  
});

//#endregion

export default router
