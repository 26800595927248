/********************************************************************************
 * vuexモジュール
 *
 * @since 2023/02/20
 * @author 松本裕介
 *
 *********************************************************************************/

//#region モジュールのインポート

  // フレームワークのインポート
  import { InjectionKey } from 'vue'
  import { createStore, useStore as baseUseStore, Store } from 'vuex'
  import createPersistedState from 'vuex-persistedstate'

  // モデルのインポート
  import { iAuth, iCrumb, iBreadcrumbs } from '@/models/Auth'; // 認証モデル

//#endregion

//#region インターフェース定義

  // ストアの型を定義する
  export interface State {
    auth: iAuth;
    header: string;
    back: string;
    breadcrumbs: iBreadcrumbs;
    mode: number;
  }

//#endregion

//#region InjectionKeyを定義する

  // InjectionKeyを定義する
  export const key: InjectionKey<Store<State>> = Symbol()
 
//#endregion

//#region Storeを作成する

  // Storeを作成する
  export const store = createStore<State>({
    state: {
      auth: {
        OrganizationId: "",
        OrganizationCode: "",
        OrganizationName: "",
        UserId: "",
        UserCode: "",
        UserName: "",
        Token: "",
        SignInTime: ""},
      header: "",
      back: "",
      breadcrumbs: {
        User: {id: "", value: ""},
        FiscalYear: 0,
        Competition: {id: "", value: ""},
        ApplySetting: {id: "", value: ""},
        ResultSetting: {id: "", value: ""},
        ApplyTeam: {id: "", value: ""},
        CompetitionTeam: {id: "", value: ""},
        CompetitionPlayer: {id: "", value: ""},
        Block: {id: "", value: ""},
        Match: {id: "", value: ""},
        MatchTeam: {id: "", value: ""},
        Set: {id: "", value: ""},
        SetTeam: {id: "", value: ""},
        Order: {id: "", value: ""},
        Change: {id: "", value: ""},
        Sign: {id: "", value: ""},
      },
      mode: 0},
    getters: {
    },
    mutations: {
      // 認証情報更新
      updateAuth (state, auth: iAuth) {
        state.auth = auth;
      },
      // ヘッダー名更新
      updateHeader (state, header: string) {
        state.header = header;
      },
      // バック情報更新
      updateBack (state, back: string) {
        state.back = back;
      },
      // ユーザー更新
      updateUser(state, User: iCrumb){
        state.breadcrumbs.User = User;
      },
      // 年度更新
      updateFiscalYear(state, FiscalYear: number){
        state.breadcrumbs.FiscalYear = FiscalYear; 
      },
      // 大会更新
      updateCompetition(state, Competition: iCrumb){
        state.breadcrumbs.Competition = Competition;
      },
      // 募集設定更新
      updateApplySetting(state, ApplySetting: iCrumb){
        state.breadcrumbs.ApplySetting = ApplySetting;
      },
      // 結果設定更新
      updateResultSetting(state, ResultSetting: iCrumb){
        state.breadcrumbs.ResultSetting = ResultSetting;
      },
      // 大会申込チーム更新
      updateApplyTeam(state, ApplyTeam: iCrumb){
        state.breadcrumbs.ApplyTeam = ApplyTeam;
      },
      // 大会参加チーム更新
      updateCompetitionTeam(state, CompetitionTeam: iCrumb){
        state.breadcrumbs.CompetitionTeam = CompetitionTeam;
      },
      // 大会参加選手更新
      updateCompetitionPlayer(state, CompetitionPlayer: iCrumb){
        state.breadcrumbs.CompetitionPlayer = CompetitionPlayer;
      },
      // ブロック管理更新
      updateBlock(state, Block: iCrumb){
        state.breadcrumbs.Block = Block;
      },
      // 試合更新
      updateMatch(state, Match: iCrumb){
        state.breadcrumbs.Match = Match;
      },
      // 試合チーム更新
      updateMatchTeam(state, MatchTeam: iCrumb){
        state.breadcrumbs.MatchTeam = MatchTeam;
      },
      // セット更新
      updateSet(state, Set: iCrumb){
        state.breadcrumbs.Set = Set;
      },
      // セットチーム更新
      updateSetTeam(state, SetTeam: iCrumb){
        state.breadcrumbs.SetTeam = SetTeam;
      },
      // オーダー更新
      updateOrder(state, Order: iCrumb){
        state.breadcrumbs.Order = Order;
      },
      // 選手交代更新
      updateChange(state, Change: iCrumb){
        state.breadcrumbs.Change = Change;
      },
      // サイン
      updateSign(state, Sign: iCrumb){
        state.breadcrumbs.Sign = Sign;
      },
      // モード
      updateMode(state, mode: number){
        state.mode = mode;
      },
    },
    actions: {
      // 認証情報設定
      setAuth({ commit }, auth: iAuth) {
        commit('updateAuth', auth);
      },
      // ヘッダー名設定
      setHeader({ commit }, header: string){
        commit('updateHeader', header);
      },
      // バック情報設定
      setBack({ commit }, back: string){
        commit('updateBack', back);
      },
      // ユーザー設定
      setUser({ commit }, User: iCrumb){
        commit('updateUser', User);
      },
      // 年度設定
      setFiscalYear({ commit }, FiscalYear: number){
        commit('updateFiscalYear', FiscalYear);
      },
      // 大会設定
      setCompetition({ commit }, Competition: iCrumb){
        commit('updateCompetition', Competition);
      },
      // 募集設定設定
      setApplySetting({ commit }, ApplySetting: iCrumb){
        commit('updateApplySetting', ApplySetting);
      },
      // 結果設定設定
      setResultSetting({ commit }, ResultSetting: iCrumb){
        commit('updateResultSetting', ResultSetting);
      },
      // 大会申込チーム設定
      setApplyTeam({ commit }, ApplyTeam: iCrumb){
        commit('updateApplyTeam', ApplyTeam);
      },
      // 大会参加チーム設定
      setCompetitionTeam({ commit }, CompetitionTeam: iCrumb){
        commit('updateCompetitionTeam', CompetitionTeam);
      },
      // 大会参加選手設定
      setCompetitionPlayer({ commit }, CompetitionPlayer: iCrumb){
        commit('updateCompetitionPlayer', CompetitionPlayer);
      },
      // ブロック管理設定
      setBlock({ commit }, Block: iCrumb){
        commit('updateBlock', Block);
      },
      // 試合設定
      setMatch({ commit }, Match: iCrumb){
        commit('updateMatch', Match);
      },
      // 試合チーム設定
      setMatchTeam({ commit }, MatchTeam: iCrumb){
        commit('updateMatchTeam', MatchTeam);
      },
      // セット設定
      setSet({ commit }, Set: iCrumb){
        commit('updateSet', Set);
      },
      // セットチーム設定
      setSetTeam({ commit }, SetTeam: iCrumb){
        commit('updateSetTeam', SetTeam);
      },
      // オーダー設定
      setOrder({ commit }, Order: iCrumb){
        commit('updateOrder', Order);
      },
      // 選手交代
      setChange({ commit }, Change: iCrumb){
        commit('updateChange', Change);
      },
      // サイン
      setSign({ commit }, Sign: iCrumb){
        commit('updateSign', Sign);
      },
      // モード
      setMode({ commit }, mode: number){
        commit('updateMode', mode);
      },
    },
    plugins: [createPersistedState({storage: window.sessionStorage})]
  })

  // 独自のuserStoreメソッドを定義する
  export function useStore () {

    // InjectionKeyをuserStoreメソッドに渡す
    return baseUseStore(key);
    
  }

//#endregion